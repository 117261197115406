.awareness-page {
  min-height: 100vh;
  padding-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.awareness-page .image {
  max-width: 375px;
  margin: auto;
}

.awareness-page .image img {
  width: 100%;
}
